import { useEffect, useState } from "react";
import { getDownloadURL, ref } from "firebase/storage";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { doc } from "firebase/firestore";
import { db, storage } from "../../config/firebaseConfig";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { Star } from "lucide-react";
import { getCandidateAge } from "@/lib/utils";

interface Props {
  candidateId: string;
}

const CandidateListItemWithoutChatPreview: React.FC<Props> = ({
  candidateId,
}) => {
  const [downloadURL, setDownloadURL] = useState<string | null>(null);
  const [candidate, loading, error] = useDocumentData(
    doc(db, `Users/${candidateId}`)
  );

  useEffect(() => {
    setDownloadURL(null);

    if (!candidate) {
      return;
    }
    if (candidate.hasProfilePic) {
      const imageRef = ref(
        storage,
        `mobileUsersFiles/${candidate.uid}/profile_image`
      );
      getDownloadURL(imageRef)
        .then((url) => {
          setDownloadURL(url);
        })
        .catch((error) => {
          console.error("Error fetching image: ", error);
        });
    }
  }, [candidate, storage]);

  if (loading || !candidate) {
    return (
      <div className="px-2.5 border-b-3 border-transparent">
        <div className="flex items-center gap-2.5">
          <Avatar className="w-10 h-10">
            <AvatarFallback>U</AvatarFallback>
          </Avatar>
          <div>
            <div className="text-sm text-gray-900"></div>
            <div className="text-xs text-gray-500"></div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    return null;
  }

  let name = candidate.givenName;
  let firstName = name?.split(" ")[0];

  const age = getCandidateAge(candidate.birthday);
  const ageDisplay = age ? `${age} år` : "";

  return (
    <div
      className={`px-2.5 border-b-3 border-transparent ${candidate.uid}`}
      key={candidate.uid + "-chat-header"}
    >
      <div className="flex items-center gap-2.5">
        <Avatar className="w-10 h-10">
          {downloadURL && (
            <AvatarImage src={downloadURL} className="object-cover" />
          )}
          <AvatarFallback>
            <span className="text-xl">
              {firstName?.[0]}
              {candidate.surname?.[0]}
            </span>
          </AvatarFallback>
        </Avatar>
        <div>
          <div className="flex items-center gap-1 text-sm">
            <span>
              {firstName} {candidate.surname}
              {ageDisplay && `, ${ageDisplay}`}
            </span>
            {candidate.candidateAlreadyMatchedWithYou && (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger>
                    <Star className="w-5 h-5 text-[#fcc772]" />
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>Kandidaten har redan matchat med din jobbannons</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            )}
          </div>
          {candidate.latestOccupation?.trim() !== "" && (
            <div className="text-xs font-normal">
              {candidate.latestOccupation}, {candidate.latestEmployer}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateListItemWithoutChatPreview;
