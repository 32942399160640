import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../config/firebaseConfig";
import { JobAd } from "../types/jobAdsTypes";
import { Candidate } from "../types/candidate";

// Helper function to get the index of a value in an array and compare it with the index of another value in another array
/* function compareIndices(
  value1: any,
  value2: any,
  array1: string | any[],
  array2: string | any[]
) {
  return array1.indexOf(value1) === array2.indexOf(value2);
}

// Helper function to check if a user's age is within a range
function checkAge(userAge: number, ageRange: string) {
  const [minAge, maxAge] = ageRange.split("-").map(Number);
  return userAge >= minAge && userAge <= maxAge;
} */

export function formatNumberSwedishStyle(number: number) {
  return new Intl.NumberFormat("sv-SE").format(number);
}

export const adFilter = (
  userData: any,
  adData: any,
  businessUser?: any
  /*  typeOfMeny?: string */
) => {
  console.log("User", userData.uid);
  const businessOrgNumber = businessUser.orgNumber;

  const userCurrentOrgNumber = userData.prevOrgNumber;

  const sameCompany = businessOrgNumber === userCurrentOrgNumber;

  if (sameCompany && userData.hide) {
    /*  console.log(
      `${userData.givenName} ${userData.surname} filtered out: Same company and hidden`
    ); */
    return false;
  }

  // ---seeked role---
  // user data
  const seekedRoleUser = ["storeSeller", "inSeller", "outSeller"];
  const seekedRoleAd = ["Butikssäljare", "Innesäljare", "Utesäljare"];

  // ---type of work hours / employment---
  // user data
  const typeOfWorkUser = ["partTime", "fullTime", "internship", "hourly"];
  const typeOfWorkAd = ["Deltid", "Heltid", "Praktik", "Timanställning"];

  // ---type of experience ---
  // user data
  const experienceUser = [0, 1, 3, 5, 10];
  const experienceAd = ["0+ år", "1+ år", "3+ år", "5+ år", "10+ år"];

  // ---region ---
  // user data
  const regionUser = [
    "falun",
    "gavle",
    "gothenburg",
    "halmstad",
    "helsingborg",
    "jonkoping",
    "kalmar",
    "karlskrona",
    "karlstad",
    "linkoping",
    "lulea",
    "lund",
    "malmo",
    "norrkoping",
    "nykoping",
    "stockholm",
    "umea",
    "uppsala",
    "visby",
    "vasteras",
    "vaxjo",
    "orebro",
    "ostersund",
  ];
  const regionAd = [
    "falun",
    "Gävle",
    "Göteborg",
    "Halmstad",
    "Helsingborg",
    "Jönköping",
    "Kalmar",
    "Karlskrona",
    "Karlstad",
    "Linköping",
    "Luleå",
    "Lund",
    "Malmö",
    "Norrköping",
    "Nyköping",
    "Stockholm",
    "Umeå",
    "Uppsala",
    "Visby",
    "Västerås",
    "Växjö",
    "Örebro",
    "Östersund",
  ];

  // ---driversLicense ---
  // user data
  const driversLicenseUser = [true, false];
  const driversLicenseAd = ["Ja", "Nej"];

  // ---langSkills english ---
  // user data
  const langSkillEngUser = [1, 2, 3];
  const langSkillEngAd = ["Nybörjare", "Medelnivå", "Flytande"];

  // ---langSkills swedish ---
  // user data
  const langSkillSwedUser = [1, 2, 3];
  const langSkillSwedAd = ["Nybörjare", "Medelnivå", "Flytande"];

  // ---seeked role---
  const userRealValueRole = seekedRoleUser.indexOf(userData.soughtOccupation);
  let resultRole: boolean;

  // Check if the ad has the new 'typeOfRoles' property
  if (adData.generalMenuData?.hasOwnProperty("typeOfRoles")) {
    // New code for the case when 'typeOfRoles' is an object
    const adRealValueRoles = Object.keys(
      adData.generalMenuData.typeOfRoles
    ).filter((role) => adData.generalMenuData.typeOfRoles[role]);

    // Check if the user's sought occupation is in the ad's roles array
    resultRole = adRealValueRoles.some((adRealValueRole) => {
      return userRealValueRole === seekedRoleAd.indexOf(adRealValueRole);
    });
  } else {
    // Old code for the case when 'typeOfRole' is a single string
    const adRealValueRole = seekedRoleAd.indexOf(
      adData.generalMenuData.typeOfRole
    );
    resultRole = userRealValueRole === adRealValueRole;
  }

  if (!resultRole) {
    /*  console.log(
      `${userData.givenName} ${userData.surname} filtered out: Role mismatch`
    ); */
  }

  // ---type of work hours / employment---
  const userTypeOfWorkArray = userData.hours
    .split(",")
    .map((item: string) => item.trim());
  const adTypeOfWork = adData.generalMenuData.formOfEmployment;
  let resultTypeOfWork: boolean;

  if (typeof adTypeOfWork === "string") {
    const adTypeOfWorkArray = adTypeOfWork
      .split(",")
      .map((item: string) => item.trim());
    resultTypeOfWork = userTypeOfWorkArray.some((userType: string) =>
      adTypeOfWorkArray.some(
        (adType: string) =>
          typeOfWorkAd.indexOf(adType) === typeOfWorkUser.indexOf(userType)
      )
    );
  } else {
    resultTypeOfWork = userTypeOfWorkArray.some(
      (userType: string) =>
        typeOfWorkAd.indexOf(adTypeOfWork) === typeOfWorkUser.indexOf(userType)
    );
  }

  if (!resultTypeOfWork) {
    console.log(
      `${userData.givenName} ${userData.surname} filtered out: Work hours/employment type mismatch`
    );
  }

  // ---type of experience ---
  const userRealexperience = experienceUser.indexOf(
    userData.experienceInSearchedOccupation
  );
  const adRealexperience = experienceAd.indexOf(
    adData.generalMenuData.experience
  );
  const resultExperience = userRealexperience >= adRealexperience;

  if (!resultExperience) {
    /*  console.log(
      `${userData.givenName} ${userData.surname} filtered out: Insufficient experience`
    ); */
  }

  // ---type of age  ---
  var currentYear = new Date().getFullYear();
  let userRealAge = 0;
  // Skip candidate if no birthday data
  if (
    !userData.birthday ||
    userData.birthday === undefined ||
    userData.birthday === null ||
    userData.birthday.length === 0
  ) {
    return false;
  }

  if (typeof userData.birthday === "string") {
    userRealAge = currentYear - parseInt(userData.birthday?.substring(0, 4));
  } else if (userData.birthday instanceof Timestamp) {
    userRealAge = currentYear - userData.birthday.toDate().getFullYear();
  }

  const transformedAdAgeData = Object.keys(
    adData.generalMenuData.prefAges
  ).reduce((o: any, key: any) => {
    adData.generalMenuData.prefAges[key] === true &&
      (o[key] = adData.generalMenuData.prefAges[key]);

    return o;
  }, {});

  const transformedAdAgeDat2 = Object.keys(transformedAdAgeData);

  const transformedAdAgeData3 = transformedAdAgeDat2.map((str: any) => {
    try {
      let strArray = str
        .substring(0, str.length - 3)
        .replace("+", "")
        .split("-");

      return strArray.map(Number);
    } catch (e) {
      console.log(e);
      console.log("ERROR_STRING", str);
    }
  });

  let resultAgeArrayOfBooleans = [] as any;

  transformedAdAgeData3.forEach((element) => {
    if (element.length === 1) {
      resultAgeArrayOfBooleans.push(element < userRealAge);
    } else {
      resultAgeArrayOfBooleans.push(
        userRealAge >= element[0] && userRealAge <= element[1]
      );
    }
  });

  const resultAge = resultAgeArrayOfBooleans.includes(true);

  if (!resultAge) {
    /*  console.log(
      `${userData.givenName} ${userData.surname} filtered out: Age mismatch`
    ); */
  }

  // ---type of remote---
  const resultRemote = userData.remote && adData.generalMenuData.region.remote;

  // ---type of region ---
  const userCities = userData.city
    .split(",")
    .map((city: string) => city.trim().toLowerCase());
  const adRegion = adData.generalMenuData.region.regionInner;
  let resultRegion: boolean;

  // Add debug logging
  /*   console.log(`${userData.givenName} ${userData.surname} Debug Info:`);
  console.log(`- User cities (raw): ${userCities}`);
  console.log(`- Ad region (raw): ${adRegion}`);
  console.log(`- Ad region type: ${typeof adRegion}`);
  console.log(`- Remote work enabled: ${resultRemote}`); */

  // Check if adRegion is a comma-separated string of multiple regions
  if (typeof adRegion === "string" && adRegion.includes(",")) {
    const adRegionArray = adRegion
      .split(",")
      .map((item) => item.trim().toLowerCase());
    resultRegion = resultRemote
      ? true
      : userCities.some((userCity: string) => adRegionArray.includes(userCity));

    /*  console.log(`- Multiple regions mode`);
    console.log(`- Ad regions (processed): ${adRegionArray.join(", ")}`); */
  } else {
    // Single region mode - use mapping
    const adRealRegion = regionAd.indexOf(adRegion);
    resultRegion = resultRemote
      ? true
      : userCities.some((userCity: string) => {
          const userCityIndex = regionUser.indexOf(userCity);
          /*    console.log(`- Mapping check for ${userCity}:`);
          console.log(`  • User city index: ${userCityIndex}`);
          console.log(`  • Ad region index: ${adRealRegion}`); */
          return userCityIndex === adRealRegion;
        });
  }

  if (!resultRegion) {
    /* console.log(
      `Result: MISMATCH - ${userData.givenName} ${userData.surname} filtered out`
    ); */
  } else {
    /*  console.log(`Result: MATCH - Region check passed`); */
  }

  // ---type of drivers license ---
  const userRealdriversLicense = driversLicenseUser.indexOf(
    userData.driversLicense
  );
  const adRealdriversLicense = driversLicenseAd.indexOf(
    adData.generalMenuData.driversLicense
  );
  const resultDriversLicense =
    adData.generalMenuData.driversLicense === "Nej"
      ? true
      : userRealdriversLicense === adRealdriversLicense;

  if (!resultDriversLicense) {
    /*  console.log(
      `${userData.givenName} ${userData.surname} filtered out: Driver's license mismatch`
    ); */
  }

  // ---langSkills eng ---
  const userRealLangSkillEng = langSkillEngUser.indexOf(userData.engLangSkill);
  const adRealLangSkillEng = langSkillEngAd.indexOf(
    adData.languageSkillsMenuData.english
  );
  const resultLangSkillEng = userRealLangSkillEng >= adRealLangSkillEng;

  if (!resultLangSkillEng) {
    /* console.log(
      `${userData.givenName} ${userData.surname} filtered out: Insufficient English language skills`
    ); */
  }

  // ---langSkills swed---
  const userRealLangSkillSwe = langSkillSwedUser.indexOf(
    userData.swedLangSkill
  );
  const adRealLangSkillSwe = langSkillSwedAd.indexOf(
    adData.languageSkillsMenuData.swedish
  );
  const resultLangSkillSwe = userRealLangSkillSwe >= adRealLangSkillSwe;

  if (!resultLangSkillSwe) {
    /*  console.log(
      `${userData.givenName} ${userData.surname} filtered out: Insufficient Swedish language skills`
    ); */
  }

  // --- Filter salary mode ---
  const salaryMode = adData.aboutTheAdData.salaryMode;
  const salaryExpectionUser = userData.salary;
  const salaryExpectationAd = adData.aboutTheAdData.salaryExpectation;
  const salaryResult =
    // Dealbreaker
    salaryMode === "dealbreaker"
      ? salaryExpectationAd >= salaryExpectionUser
      : // Flex adds 15% to the salary expectation
      salaryMode === "flex"
      ? salaryExpectationAd * 1.15 >= salaryExpectionUser
      : // Else return true for all other cases
        true;

  if (!salaryResult) {
    /* console.log(
      `${userData.givenName} ${userData.surname} filtered out: Salary mismatch`
    ); */
  }

  const finalArrayCheckIfAllTrue = [
    resultRole,
    resultTypeOfWork,
    resultExperience,
    resultAge,
    resultRegion,
    resultDriversLicense,
    resultLangSkillEng,
    resultLangSkillSwe,
    salaryResult,
  ];

  const result = finalArrayCheckIfAllTrue.every((val) => val === true);

  if (result) {
    console.log(`${userData.givenName} ${userData.surname} passed all filters`);
  } else {
    console.log(
      `${userData.givenName} ${
        userData.surname
      } filtered out: Failed one or more filters: ${finalArrayCheckIfAllTrue
        .map((val, index) => `${index + 1}. ${val}`)
        .join("\n")}`
    );
  }

  return result;
};

export function isEmpty(val: string) {
  return val === undefined || val == null || val.length <= 0;
}

export function isValidOrgNummer(input: string) {
  // Remove any hyphens or spaces in the input
  input = input.replace(/[- ]/g, "");

  // Ensure input is 10 characters long
  if (input.length !== 10) {
    return false;
  }

  // Check that the third digit is at least a 2
  if (parseInt(input.charAt(2), 10) < 2) {
    return false;
  }

  // Calculate the control digit
  let sum = 0;
  for (let i = 0; i < 9; i++) {
    let digit = parseInt(input.charAt(i), 10);
    if (i % 2 === 0) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }
    sum += digit;
  }
  const controlDigit = (10 - (sum % 10)) % 10;

  // Verify that the control digit matches
  const inputControlDigit = parseInt(input.charAt(9), 10);
  return controlDigit === inputControlDigit;
}

export const getUsers = async (selectedAd: any, businessUser: any) => {
  // const date2 = new Date().toLocaleDateString('sv-SE');
  var currentYear = new Date().getFullYear();

  // const currentlySelectedAd = await getCurrentlySelectedAd(selectedAd)
  try {
    const usersMatchedWithAd = await getCurrentlySelectedAdSpecificCollection(
      selectedAd?.adId,
      "UsersMatchedWithAd"
    );
    const businessMatchedWithUser =
      await getCurrentlySelectedAdSpecificCollection(
        selectedAd?.adId,
        "BusinessMatchedWithUsers"
      );

    const usersQuitProcess = await getCurrentlySelectedAdSpecificCollection(
      selectedAd?.adId,
      "UsersQuitProcess"
    );

    // integrate in button where business click "spara"
    const businessSeenUsersQuitProcess =
      await getCurrentlySelectedAdSpecificCollection(
        selectedAd?.adId,
        "BusinessSeenUsersQuitProcess"
      );

    const businessQuitProcessWithUser =
      await getCurrentlySelectedAdSpecificCollection(
        selectedAd?.adId,
        "BusinessQuitProcessWithUser"
      );

    const businessHiredCandidate =
      await getCurrentlySelectedAdSpecificCollection(
        selectedAd?.adId,
        "BusinessHiredCandidate"
      );

    const adData = await getAdData(selectedAd?.adId);
    const finalArray: any[] | PromiseLike<any[]> = [];

    const q = query(collection(db, "Users"));

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      const checkIfBothPartiesMatched =
        usersMatchedWithAd?.includes(doc.data()?.uid) &&
        businessMatchedWithUser?.includes(doc.data()?.uid);

      //console.log("TESTFIRED",  doc?.data().givenName, doc?.data(), adData, businessUser)

      if (
        doc?.data().doneWithFirstSetup &&
        adFilter(
          doc?.data() as {
            prevOrgNumber: any;
            hide: any;
            soughtOccupation: any;
            hours: any;
            experienceInSearchedOccupation: any;
            birthday: string;
            remote: any;
            city: any;
            driversLicense: boolean;
            engLangSkill: any;
            swedLangSkill: any;
            salary: any;
          },
          adData as JobAd,
          businessUser
        )
      ) {
        if (checkIfBothPartiesMatched) {
          // const userMatchedWithAdTemp = usersMatchedWithAd.includes(doc.data()?.uid);

          if (doc.data()?.givenName !== undefined) {
            let name = doc.data()?.givenName;
            let firstName = name.split(" ")[0];

            // console.log("TESTFIRED",  doc.data()?.givenName, name)

            const haveUserQuitProcess = usersQuitProcess?.includes(
              doc.data()?.uid
            );
            const haveBusinessSeenQuitMessage =
              businessSeenUsersQuitProcess?.includes(doc.data()?.uid);
            const haveBusinessQuitProcessWithUser =
              businessQuitProcessWithUser?.includes(doc.data()?.uid);
            const haveBusinessHiredCandidate = businessHiredCandidate?.includes(
              doc.data()?.uid
            );

            if (haveBusinessHiredCandidate) {
            } else if (haveUserQuitProcess && !haveBusinessSeenQuitMessage) {
              finalArray.push({
                uid: doc.data()?.uid,
                personName: firstName + " " + doc.data()?.surname,
                age:
                  currentYear - parseInt(doc.data()?.birthday.substring(0, 4)), //doc.data()?.birthday,
                currentJobTitle: doc.data()?.latestOccupation,
                currentCompany: doc.data()?.latestEmployer,
                personalDocuments: [
                  doc.data()?.hasCv ? "CV" : "",
                  doc.data()?.hasCoverLetter ? "Personligt brev" : "",
                  doc.data()?.linkedinProfileLink.length > 0
                    ? doc.data()?.linkedinProfileLink
                    : "",
                ],
                contentText: doc.data()?.aboutMeText,
                // candidateAlreadyMatchedWithYou: userMatchedWithAdTemp, // needs to check jobAds
                youMatchedWithCandidate: false, // needs to check jobAds
                bothPartiesMatched: false, // needs to check jobAds
                removedCandidate: false, // needs to check jobAds
                realFirebaseUserData: doc.data(),

                userQuit: true,
                userHired: false,
              });
            } else if (haveUserQuitProcess && haveBusinessSeenQuitMessage) {
            } else if (
              !haveUserQuitProcess &&
              !haveBusinessQuitProcessWithUser
            ) {
              finalArray.push({
                uid: doc.data()?.uid,
                personName: firstName + " " + doc.data()?.surname,
                age:
                  currentYear - parseInt(doc.data()?.birthday.substring(0, 4)), //doc.data()?.birthday,
                currentJobTitle: doc.data()?.latestOccupation,
                currentCompany: doc.data()?.latestEmployer,
                personalDocuments: [
                  doc.data()?.hasCv ? "CV" : "",
                  doc.data()?.hasCoverLetter ? "Personligt brev" : "",
                  doc.data()?.linkedinProfileLink
                    ? doc.data()?.linkedinProfileLink
                    : "",
                ],
                contentText: doc.data()?.aboutMeText,
                // candidateAlreadyMatchedWithYou: userMatchedWithAdTemp, // needs to check jobAds
                youMatchedWithCandidate: false, // needs to check jobAds
                bothPartiesMatched: false, // needs to check jobAds
                removedCandidate: false, // needs to check jobAds
                realFirebaseUserData: doc.data(),

                userQuit: false,
                userHired: false,
              });
            }
          }
        }

        // console.log( finalArray.filter(obj => obj !== undefined));
      }
    });

    //console.log("TESTFIRED",   finalArray)

    return finalArray.filter((obj: any) => obj !== undefined);
  } catch (e) {
    console.log(e);
  }
};

export const getCurrentlySelectedAdSpecificCollection = async (
  selectedAd: string,
  collectionName: string
): Promise<any[] | undefined> => {
  try {
    const subColRef = collection(db, "JobAds", selectedAd, collectionName);

    const qSnap = await getDocs(subColRef);

    return qSnap.docs.map((d) => d.data().uid);
  } catch (e) {
    console.log(e);
  }
};

export const getReciprocalMatchData = async (selectedAd: string) => {
  try {
    const matchesRef = collection(
      db,
      "JobAds",
      selectedAd,
      "ReciprocalMatches"
    );

    const qSnap = await getDocs(matchesRef);

    return qSnap.docs.map((d) => d.data());
  } catch (e) {
    console.log(e);
  }
};

export const getReciprocalMatchCandidateIds = async (selectedAd: string) => {
  try {
    const q = query(
      collection(db, "TopLevelReciprocalMatches"),
      where("jobAdId", "==", selectedAd)
    );

    const querySnapshot = await getDocs(q);

    // Filter documents where status is "open" or status is undefined
    const filteredDocs = querySnapshot.docs.filter((d) => {
      const data = d.data();
      console.log("DATA", d.id);

      return !data.status || data.status === "open";
    });

    // Map the filtered documents to their candidateId
    return filteredDocs.map((d) => {
      return {
        matchId: d.id,
        candidateId: d.data().candidateId,
      };
    });

    //return querySnapshot.docs.map((d) => d.data().candidateId);
  } catch (e) {
    console.log(e);
  }
};

export const getReciprocalMatchForCandidate = async (
  selectedAd: string,
  candidateId: string
) => {
  try {
    const q = query(
      collection(db, "TopLevelReciprocalMatches"),
      where("jobAdId", "==", selectedAd),
      where("candidateId", "==", candidateId)
    );

    const querySnapshot = await getDocs(q);

    return querySnapshot.docs.map((d) => d.data());
  } catch (e) {
    console.log(e);
  }
};

const getAdData = async (selectedAd: string) => {
  // const date2 = new Date().toLocaleDateString('sv-SE');

  try {
    const docRef = doc(db, "JobAds", selectedAd);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return docSnap.data();
    } else {
      // doc.data() will be undefined in this case
    }
  } catch (err) {
    console.log(err);
  }
};

export const roleMap = {
  inSeller: "Innesäljare",
  outSeller: "Utesäljare",
  storeSeller: "Butikssäljare",
};

export const obfuscateUserData = (data: Candidate) => {
  const obfuscateString = (str: string) => str.replace(/./g, "*");
  const obfuscateNumber = (num: number) => num.toString().replace(/./g, "*");

  let obfuscatedData: Candidate = { ...data };

  for (const key in data) {
    const value = data[key];
    if (typeof value === "string") {
      obfuscatedData[key] = obfuscateString(value);
    } else if (typeof value === "number") {
      obfuscatedData[key] = obfuscateNumber(value);
    } else {
      // Handle other types if needed, or throw an error if unexpected type is encountered
    }
  }

  return obfuscatedData;
};

export function calculateMatchScore(adTags: string[], candidateTags: string[]) {
  // Count how many ad tags are present in the candidate's tags
  const matchingTagsCount = adTags.filter((tag) =>
    candidateTags.includes(tag)
  ).length;

  // Calculate the score as a percentage
  const score = (matchingTagsCount / adTags.length) * 100;

  // Round score to zero decimals before returning
  return Math.round(score);
}
