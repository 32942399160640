import { clsx, type ClassValue } from "clsx";
import { Timestamp } from "firebase/firestore";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function getCandidateAge(
  birthday: string | Timestamp | undefined
): number | null {
  if (!birthday) return null;

  let birthYear: number;
  if (birthday instanceof Timestamp) {
    birthYear = birthday.toDate().getFullYear();
  } else if (typeof birthday === "string") {
    // Handle string format (assuming YYYY-MM-DD or similar)
    birthYear = parseInt(birthday.substring(0, 4));
  } else {
    return null;
  }

  return new Date().getFullYear() - birthYear;
}
